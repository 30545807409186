
import React from "react"; import AppLayout from "./layout"; import { useHistory } from "react-router-dom"; import queryString 
from "query-string";

export default function Idle() {
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  console.log(parsed)

 setTimeout(function(){ history.push('/') }, 6000);
  return (
    <AppLayout title={""} style={{padding:0}}>
      <img
        src={process.env.PUBLIC_URL + "/5.gif"}
        alt="Open Cabinet"
        style={{ width: "100%", height: "100%" }}
      />
      <div
      
      style={{
        position: "absolute",
        width: "82%",
        height: "220px",
        left: "5vh",
        top: "71vh",
        background: "#F3F4F8",
        boxShadow: "0px 5px 25px 5px rgb(0 0 0 / 10%)",
        borderRadius: "50px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      }}
      >
        <p style={{
          fontFamily: 'Roboto',
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "64px",
          lineHeight: "82px",
          /* identical to box height */
          
          textAlign: "center",
          
          /* black */
          
          color: "#0F0F0F"
        }}>₺ {parsed.value || 0.0}</p>
      </div>
    </AppLayout>
  );
}
